import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import Carousel from '../components/Carousel';

const About = () => {
  return (
    <div className="about">
      <header className="about-header">
        <h1>Confy.io: Transforming Interior Design</h1>
        <p>Revolutionizing the way you visualize and design your interiors.</p>
        <Link to="/services">
          <button className="cta-button">Learn More</button>
        </Link>
      </header>
      <section className="about-content">
        <h2>Who We Are</h2>
        <p>
          We are Confy.io, a team of dedicated professionals passionate about transforming the way people design their interiors. Our mission is to provide innovative, high-quality visualization tools that make interior design accessible and enjoyable for everyone. With years of experience and a commitment to excellence, we are your trusted partner in creating stunning and functional living spaces.
        </p>
        <Link to="/request-demo">
          <button className="cta-button">Get Started</button>
        </Link>
      </section>
      <section className="about-carousel">
        <h2>Our Customers</h2>
        <Carousel />
      </section>
      <section className="about-people">
        <h2>People make the difference</h2>
        <p>
          With a diverse team of dedicated professionals, we offer our partners a unique set of perspectives and ideas that ensure the success of their projects.
        </p>
      </section>
      <section className="about-articles">
        <h2>Our Latest Articles</h2>
        <div className="articles">
          <article>
            <h3>Top 10 Tips for Boosting Sales Using Confy.io Configurator</h3>
            <p>
              Discover the power of Confy.io's configurator to enhance your sales strategy. These top 10 tips will help you leverage the tool to its fullest potential, attracting more customers and closing more deals.
            </p>
            <div className="additional-content show">
              <h3>1. Provide Interactive Experiences</h3>
              <p>
                Engage your customers with interactive experiences. Confy.io's configurator allows users to visualize products in their space, making it easier to imagine owning them.
              </p>
              <h3>2. Offer Customization Options</h3>
              <p>
                Allow customers to customize products to their preferences. Personalized options can significantly boost interest and conversion rates.
              </p>
              <h3>3. Showcase Realistic Visuals</h3>
              <p>
                Use high-quality 3D renderings to showcase your products. Realistic visuals help customers see exactly what they are getting, reducing hesitation and increasing confidence in their purchase.
              </p>
              <h3>4. Simplify the Decision-Making Process</h3>
              <p>
                The configurator simplifies complex decision-making by allowing customers to see different combinations of products and styles in real-time.
              </p>
              <h3>5. Enhance Mobile Accessibility</h3>
              <p>
                Ensure your configurator is mobile-friendly. Many customers shop on their phones, and a seamless mobile experience can lead to more sales.
              </p>
              <h3>6. Integrate with E-commerce Platforms</h3>
              <p>
                Integrate Confy.io's configurator with your e-commerce platform for a smooth transition from visualization to purchase. This reduces friction and improves the overall shopping experience.
              </p>
              <h3>7. Utilize Data Analytics</h3>
              <p>
                Use the data generated by the configurator to understand customer preferences and trends. This information can inform your marketing strategies and product offerings.
              </p>
              <h3>8. Promote on Social Media</h3>
              <p>
                Share interactive visualizations on social media to attract potential customers. Eye-catching posts can drive traffic to your website and increase engagement.
              </p>
              <h3>9. Offer Virtual Consultations</h3>
              <p>
                Combine the configurator with virtual consultations to provide personalized advice and build trust with your customers. This can lead to higher conversion rates.
              </p>
              <h3>10. Provide Clear Call-to-Actions</h3>
              <p>
                Ensure that your configurator has clear call-to-actions, guiding customers towards making a purchase or contacting your sales team for more information.
              </p>
            </div>
          </article>
          <article>
            <h3>Transforming Spaces: The Power of the Interior Configurator by Confy.io</h3>
            <p>
              In the world of interior design, imagination is boundless. Homeowners and designers alike yearn for tools that can bridge the gap between vision and reality. Enter the Interior Configurator by Confy.io—a groundbreaking innovation set to redefine the design experience. This powerful tool empowers users to visualize, customize, and perfect their interior spaces with unparalleled ease and precision.
            </p>
            <div className="additional-content show">
              <h3>A New Era in Interior Design</h3>
              <p>
                Gone are the days of relying solely on sketches and mood boards to convey a design concept. The Interior Configurator by Confy.io leverages cutting-edge technology to bring your ideas to life. This interactive tool allows users to explore different design elements in real-time, making it easier than ever to see how various combinations of colors, materials, and furnishings will look in an actual space.
              </p>
              <h3>User-Friendly Interface</h3>
              <p>
                One of the standout features of the Interior Configurator is its user-friendly interface. Designed with both professionals and novices in mind, the configurator is intuitive and easy to navigate. Users can start by selecting a room template or uploading their own space. From there, the possibilities are endless. Change the wall colors, swap out flooring, experiment with different furniture arrangements, and even adjust lighting—all with a few simple clicks.
              </p>
              <h3>High-Quality Visuals</h3>
              <p>
                Visualization is at the heart of the Confy.io experience. The configurator boasts high-quality 3D renderings that provide a realistic representation of your design choices. This attention to detail ensures that what you see on the screen closely matches what you’ll see in real life, allowing for more confident decision-making.
              </p>
              <h3>Customization at Its Best</h3>
              <p>
                Every space is unique, and the Interior Configurator embraces this individuality. Users can customize nearly every aspect of their design, from the type of wood used in cabinetry to the pattern of the tiles on the floor. This level of customization ensures that the final design reflects personal tastes and preferences, resulting in a truly bespoke space.
              </p>
              <h3>Seamless Integration</h3>
              <p>
                For interior designers, the configurator offers seamless integration with other design tools and software. This compatibility enhances workflow efficiency, allowing designers to easily incorporate client feedback and make adjustments on the fly. Moreover, the ability to share designs directly with clients fosters better communication and collaboration.
              </p>
              <h3>Eco-Friendly Design Solutions</h3>
              <p>
                In today’s world, sustainability is more important than ever. The Interior Configurator by Confy.io includes features that promote eco-friendly design solutions. Users can explore sustainable materials and energy-efficient options, helping to create beautiful spaces that are kind to the planet.
              </p>
              <h3>Bringing Dreams to Life</h3>
              <p>
                The Interior Configurator by Confy.io is more than just a tool—it’s a gateway to creativity and innovation. Whether you’re a homeowner dreaming of a kitchen makeover, a designer working on a client’s living room, or a developer planning a large-scale project, the configurator provides the flexibility and functionality needed to bring your vision to life.
              </p>
              <h3>Conclusion</h3>
              <p>
                In a field where visual appeal and functional design go hand in hand, the Interior Configurator by Confy.io stands out as a game-changer. It transforms the design process, making it more interactive, engaging, and precise. By bridging the gap between imagination and reality, it empowers users to create spaces that are not only beautiful but also uniquely theirs. Discover the future of interior design with Confy.io, where your dream spaces are just a few clicks away.
              </p>
            </div>
          </article>
        </div>
      </section>
      <footer className="about-footer">
        <h2>Ready to transform your shopping experience?</h2>
        <Link to="/request-demo">
          <button className="cta-button">Get Started</button>
        </Link>
      </footer>
    </div>
  );
};

export default About;
