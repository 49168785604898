import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import backgroundImage from '../assets/demo/background.jpg';
import './RequestDemo.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 70px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const FormContainer = styled.div`
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
`;

const HeaderStyled = styled.h1`
  font-size: 2em;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1.2em;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
`;

const Select = styled.select`
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
`;

const Textarea = styled.textarea`
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
`;

const Button = styled.button`
  padding: 14px;
  margin: 16px 0;
  background-color: #f96332;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  cursor: pointer;
  &:hover {
    background-color: #e6521e;
  }
`;

const RequestDemo = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    companyUrl: '',
    industry: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sending data:', formData); // Добавлено для отладки
    axios.post('https://my-feedback-form-server-2e2b04e3005a.herokuapp.com/send', formData)
      .then(response => {
        console.log('Response:', response); // Добавлено для отладки
        alert('Message sent successfully');
      })
      .catch(error => {
        console.error('There was an error sending the message!', error);
      });
  };

  return (
    <Container>
      <Overlay />
      <FormContainer>
        <HeaderStyled>Connect with an expert</HeaderStyled>
        <Description>
          Curious about how Confy.io can boost your business? Complete the form, and we will reach out soon to set up a meeting.
        </Description>
        <Form onSubmit={handleSubmit}>
          <Input type="text" name="firstName" placeholder="First name" onChange={handleChange} required />
          <Input type="text" name="lastName" placeholder="Last name" onChange={handleChange} required />
          <Input type="email" name="email" placeholder="Work email" onChange={handleChange} required />
          <Input type="tel" name="phoneNumber" placeholder="Phone number" onChange={handleChange} required />
          <Input type="text" name="jobTitle" placeholder="Job title" onChange={handleChange} required />
          <Input type="text" name="companyUrl" placeholder="Company URL" onChange={handleChange} required />
          <Select name="industry" onChange={handleChange} required>
            <option value="">Industry</option>
            <option value="Retail">Retail</option>
            <option value="E-commerce">E-commerce</option>
            <option value="Manufacturing">Manufacturing</option>
          </Select>
          <Textarea name="message" placeholder="Message" rows="4" onChange={handleChange}></Textarea>
          <Button type="submit">Book now</Button>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default RequestDemo;
