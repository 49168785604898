import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Закрытие меню при смене страницы
  useEffect(() => {
    closeMenu();
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="logo">
        <img src="/logo.png" alt="Company Logo" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
      <nav className={menuOpen ? 'nav-open' : ''}>
        <ul>
          <li>
            <NavLink to="/" exact activeClassName="active" onClick={closeMenu}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active" onClick={closeMenu}>About</NavLink>
          </li>
          <li>
            <NavLink to="/services" activeClassName="active" onClick={closeMenu}>Services</NavLink>
          </li>
          <li>
            <NavLink to="/request-demo" activeClassName="active" onClick={closeMenu}>Request Demo</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
