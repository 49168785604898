import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import './carousel.css';

import image1 from '../assets/carousel/image1.png';
import image2 from '../assets/carousel/image2.png';
import image3 from '../assets/carousel/image3.png';
import image4 from '../assets/carousel/image4.png';
import image5 from '../assets/carousel/image5.png';

const Carousel = () => {
  return (
    <div className="elementor-image-carousel swiper-wrapper">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={10}
        slidesPerView={5}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide>
          <img src={image1} alt="Image 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} alt="Image 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} alt="Image 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image4} alt="Image 4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image5} alt="Image 5" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
