// src/pages/MultisurfaceDemo.js

import React from 'react';
import './MultisurfaceDemo.css';

const MultisurfaceDemo = () => {
  const demoRooms = [
    { title: 'L-shaped Kitchen', imageUrl: require('../assets/multi-asset/l-shaped-kitchen.jpg') },
    { title: 'Galley Kitchen', imageUrl: require('../assets/multi-asset/galley-kitchen.jpg') },
    { title: 'Island Kitchen', imageUrl: require('../assets/multi-asset/island-kitchen.jpg') },
    { title: 'Bathroom 1', imageUrl: require('../assets/multi-asset/bathroom-1.jpg') },
    { title: 'Bathroom 2', imageUrl: require('../assets/multi-asset/bathroom-2.jpg') },
    { title: 'Dining Room', imageUrl: require('../assets/multi-asset/dining-room.jpg') },
    { title: 'Living Room', imageUrl: require('../assets/multi-asset/living-room.jpg') },
    { title: 'Bed Room', imageUrl: require('../assets/multi-asset/bed-room.jpg') },
  ];

  return (
    <div className="multisurface-demo">
      <header className="header">
        <h1>Multisurface Demo</h1>
      </header>
      <section className="content">
        <p>Welcome to the Multisurface Demo page. Here you can explore various surface options for your project.</p>
        <div className="grid">
          {demoRooms.map((room, index) => (
            <div key={index} className="grid-item">
              <img src={room.imageUrl} alt={room.title} />
              <h3>{room.title}</h3>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default MultisurfaceDemo;
