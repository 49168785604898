// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementor-image-carousel {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px 0;
}

.elementor-image-carousel img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0 10px;
  max-height: 120px; /* Ограничиваем высоту изображения */
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

/* Медиазапросы для адаптивности */

@media (max-width: 1024px) {
  .elementor-image-carousel img {
    max-width: 150px;
    max-height: 150px; /* Ограничиваем высоту изображения для средней ширины экрана */
  }
}

@media (max-width: 768px) {
  .elementor-image-carousel img {
    max-width: 100px;
    max-height: 100px; /* Ограничиваем высоту изображения для малой ширины экрана */
  }
}

@media (max-width: 480px) {
  .elementor-image-carousel img {
    max-width: 80px;
    max-height: 80px; /* Ограничиваем высоту изображения для самой малой ширины экрана */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/carousel.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,iBAAiB,EAAE,oCAAoC;AACzD;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,kCAAkC;;AAElC;EACE;IACE,gBAAgB;IAChB,iBAAiB,EAAE,8DAA8D;EACnF;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,iBAAiB,EAAE,4DAA4D;EACjF;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB,EAAE,kEAAkE;EACtF;AACF","sourcesContent":[".elementor-image-carousel {\n  width: 100%;\n  margin: 20px 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #f9f9f9;\n  padding: 20px 0;\n}\n\n.elementor-image-carousel img {\n  max-width: 100%;\n  height: auto;\n  object-fit: contain;\n  margin: 0 10px;\n  max-height: 120px; /* Ограничиваем высоту изображения */\n}\n\n.swiper-wrapper {\n  display: flex;\n  align-items: center;\n}\n\n/* Медиазапросы для адаптивности */\n\n@media (max-width: 1024px) {\n  .elementor-image-carousel img {\n    max-width: 150px;\n    max-height: 150px; /* Ограничиваем высоту изображения для средней ширины экрана */\n  }\n}\n\n@media (max-width: 768px) {\n  .elementor-image-carousel img {\n    max-width: 100px;\n    max-height: 100px; /* Ограничиваем высоту изображения для малой ширины экрана */\n  }\n}\n\n@media (max-width: 480px) {\n  .elementor-image-carousel img {\n    max-width: 80px;\n    max-height: 80px; /* Ограничиваем высоту изображения для самой малой ширины экрана */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
