// src/pages/LShapedKitchen.js

import React from 'react';
import './LShapedKitchen.css'; // Импорт стилей для этой страницы

const LShapedKitchen = () => {
    return (
        <div className="lshaped-kitchen">
            <header className="header">
                <h1>L-shaped Kitchen</h1>
            </header>
            <section className="content">
                <img src={require('../assets/multi-asset/l-shaped-kitchen.jpg')} alt="L-shaped Kitchen" />
                <p>
                    Here is the detailed view of the L-shaped Kitchen. You can explore the different aspects of this kitchen layout, 
                    including its design, functionality, and customization options.
                </p>
            </section>
        </div>
    );
};

export default LShapedKitchen;
