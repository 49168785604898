// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-content {
  flex: 1 1;
}

/* Медиазапросы для адаптивности */

@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .App-logo {
    height: 30vmin;
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(6px + 2vmin);
  }

  .App-logo {
    height: 20vmin;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAO;AACT;;AAEA,kCAAkC;;AAElC;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.main-content {\n  flex: 1;\n}\n\n/* Медиазапросы для адаптивности */\n\n@media (max-width: 768px) {\n  .App-header {\n    font-size: calc(8px + 2vmin);\n  }\n\n  .App-logo {\n    height: 30vmin;\n  }\n}\n\n@media (max-width: 480px) {\n  .App-header {\n    font-size: calc(6px + 2vmin);\n  }\n\n  .App-logo {\n    height: 20vmin;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
