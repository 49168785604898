// src/pages/Home.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Visualizer from '../components/Visualizer';
import Carousel from '../components/Carousel';
import '../App.css';
import './home.css';
import demoVideo from '../assets/demo-video.mp4';
import fingerImg from '../assets/finger.png';

const demoRooms = [
  { title: 'L-shaped Kitchen', imageUrl: require('../assets/multi-asset/l-shaped-kitchen.jpg') }
];

function Home() {
  const navigate = useNavigate();
  const [iframeVisible, setIframeVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState({
    intro: false,
    keyFeatures: false,
    whyChoose: false,
    benefits: false,
    conclusion: false
  });

  const toggleIframe = () => {
    setIframeVisible(!iframeVisible);
  };

  const toggleContent = (section) => {
    setContentVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleRequestDemo = () => {
    navigate('/request-demo');
  };

  return (
    <div className="Home">
      <header className="Home-header">
      </header>
      <main>
        <Visualizer />
        <Carousel />
        <div className="video-section">
          <h2>Watch the Demo Video</h2>
          <video controls className="demo-video">
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="multisurface-demo">
          <header className="header">
            <h1>Interior Configurator (click to launch the app)</h1>
          </header>
          <section className="content">
            <p>Welcome to the Interior Configurator. Here you can explore various surface and assets options for your project.</p>
            <div className="grid">
              {demoRooms.map((room, index) => (
                <div
                  key={index}
                  className="grid-item"
                  onClick={room.title === 'L-shaped Kitchen' ? toggleIframe : null}
                  style={{ cursor: room.title === 'L-shaped Kitchen' ? 'pointer' : 'default' }}
                >
                  <img src={room.imageUrl} alt={room.title} />
                  {room.title === 'L-shaped Kitchen' && (
                    <img src={fingerImg} alt="Finger pointing" className="finger" />
                  )}
                  <h3>{room.title}</h3>
                </div>
              ))}
            </div>
          </section>
        </div>
        <iframe
          src="https://confyio-f44e8a9160e6.herokuapp.com/"
          className={`hidden-iframe ${iframeVisible ? 'visible-iframe' : ''}`}
          title="App"
        ></iframe>
        {iframeVisible && (
          <button className="iframe-button" onClick={toggleIframe}>
            Hide App
          </button>
        )}
<section className="seo-section">
  <h2>What is an Interior Configurator?</h2>
  <p>
    An interior configurator is a powerful digital tool that enables users to design and visualize interior spaces in real-time. 
    This software allows users, whether homeowners, interior designers, or real estate professionals, to customize various aspects of a room, 
    such as furniture, flooring, wall colors, and decor, providing a virtual preview of the final look before any physical changes are made. 
    By integrating advanced 3D modeling and rendering technologies, interior configurators deliver highly realistic images that help in making informed design decisions.
  </p>
  <div className="grid">
    <div className="grid-item">
      <h3>Key Features of an Interior Configurator</h3>
      <p>
        User-Friendly Interface: Most interior configurators feature intuitive interfaces that make it easy for users of all skill levels to navigate and create designs. 
        Real-Time Visualization: Users can see changes instantly as they adjust different elements of the room. 
        Extensive Product Libraries: Interior configurators often come with extensive libraries of products from various brands. 
        High-Quality 3D Rendering: Advanced rendering technologies provide highly detailed and lifelike images. 
        Customizability: Users can customize almost every aspect of their design.
      </p>
    </div>
    <div className="grid-item">
      <h3>Why Choose Confy.io for Your Interior Configurator Needs?</h3>
      <p>
        At <a href="https://www.confy.io">Confy.io</a>, we specialize in providing top-of-the-line interior configurator solutions. 
        Cutting-Edge Technology: Confy.io utilizes the latest in 3D modeling and rendering technology. 
        Extensive Product Integration: We collaborate with numerous leading brands to ensure our product libraries are extensive. 
        Superior Customization: Our configurators offer unparalleled customization options. 
        User-Centric Design: At Confy.io, we prioritize user experience.
      </p>
    </div>
    <div className="grid-item">
      <h3>Benefits of Using Confy.io's Interior Configurator</h3>
      <p>
        Enhanced Customer Experience: Offering an interior configurator from Confy.io can significantly improve your customers' shopping experience. 
        Informed Decision-Making: With Confy.io's high-quality visualizations, users can make informed decisions. 
        Time and Cost Savings: Our configurators streamline the design process, saving time and reducing costs.
      </p>
    </div>
    <div className="grid-item">
      <h3>Conclusion</h3>
      <p>
        An interior configurator is an essential tool for anyone involved in designing or renovating interior spaces. 
        Confy.io's advanced interior configurator stands out as the premier choice. 
        Transform your design process and elevate customer experiences with Confy.io's innovative solutions. 
        Visit <a href="https://www.confy.io">Confy.io</a> to learn more and request a demo today.
      </p>
    </div>
  </div>
</section>
        <footer className="about-footer">
          <h2>Ready to transform your shopping experience?</h2>
          <button className="cta-button" onClick={handleRequestDemo}>Get Started</button>
        </footer>
      </main>
    </div>
  );
}

export default Home;
