import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';
import image1 from '../assets/services/image1.png';
import image2 from '../assets/services/image2.png';
import image3 from '../assets/services/image3.png';
import image4 from '../assets/services/image4.png';
import image5 from '../assets/services/image5.png';
import image7 from '../assets/services/image7.png';

const Services = () => {
  const navigate = useNavigate();

  const handleRequestDemo = () => {
    navigate('/request-demo');
  };

  return (
    <div className="services">
      <header className="services-header">
        <h1>Where imagination meets results</h1>
        <p>We create easy ways for homeowners to visualize home improvement products adding real value to their life.</p>
        <button className="cta-button" onClick={handleRequestDemo}>Contact us</button>
      </header>
      <section className="services-content">
        <h2>Our Services</h2>
        <p>
          At Confy.io, we specialize in delivering top-tier configurator solutions that empower homeowners and businesses to visualize and enhance their spaces with ease. Our innovative services are designed to simplify the decision-making process and bring your creative visions to life.
        </p>
      </section>
      <section className="services-features">
        <div className="feature">
          <img src={image1} alt="Intuitive Design Interface" />
          <h3>Intuitive Design Interface</h3>
          <p>
            Our user-friendly design interface allows you to effortlessly create and customize your home improvement projects. Whether you're planning a new kitchen layout or selecting the perfect flooring, our tools make it simple to visualize every detail.
          </p>
        </div>
        <div className="feature">
          <img src={image2} alt="Real-Time Updates" />
          <h3>Real-Time Updates</h3>
          <p>
            Stay in control of your project with our real-time update feature. As you make changes to your design, see them instantly reflected on the screen. This immediate feedback helps you make informed decisions, experiment with different options, and achieve the desired outcome without any guesswork.
          </p>
        </div>
        <div className="feature">
          <img src={image3} alt="Comprehensive Product Library" />
          <h3>Comprehensive Product Library</h3>
          <p>
            Explore our vast collection of products and materials from leading brands in the industry. Our library includes a wide range of options for flooring, cabinetry, countertops, backsplashes, and more. Each product comes with detailed specifications and high-quality images, allowing you to compare and choose the best fit for your project.
          </p>
        </div>
        <div className="feature">
          <img src={image4} alt="Virtual Reality Integration" />
          <h3>Virtual Reality Integration</h3>
          <p>
            Take your design experience to the next level with our virtual reality (VR) integration. Walk through your customized space in an immersive VR environment to get a true sense of scale and aesthetics. This cutting-edge technology provides a realistic preview, ensuring that what you see is exactly what you'll get.
          </p>
        </div>
        <div className="feature">
          <img src={image5} alt="Collaboration and Sharing" />
          <h3>Collaboration and Sharing</h3>
          <p>
            Share your designs with family, friends, or professionals for feedback and collaboration. Our platform allows you to export your projects in various formats, including images and 3D models. Whether you're seeking input from a contractor or showcasing your ideas to loved ones, our sharing tools make it easy.
          </p>
        </div>
        <div className="feature">
          <img src={image7} alt="Custom Solutions for Businesses" />
          <h3>Custom Solutions for Businesses</h3>
          <p>
            For businesses in the home improvement industry, we offer custom configurator solutions tailored to your needs. From branded interfaces to product integration, we work closely with you to develop a tool that enhances your customer experience and drives sales. Our solutions are scalable and adaptable to various business models, ensuring seamless integration with your existing systems.
          </p>
        </div>
      </section>
      <section className="services-testimonials">
        <h2>What our customers are saying</h2>
        <div className="testimonial">
          <p>"Confy.io has revolutionized the way we showcase our flooring products to customers. The ability to provide real-time visualizations has significantly increased our sales and customer satisfaction."</p>
          <p>- Sarah L., Sales Manager</p>
        </div>
        <div className="testimonial">
          <p>"As a leading renovation company, we've seen a tremendous improvement in our project presentations with Confy.io. Clients love being able to see their design choices instantly, making the decision-making process smoother and more efficient."</p>
          <p>- Michael R., CEO</p>
        </div>
      </section>
      <footer className="services-footer">
        <h2>Ready to transform your shopping experience?</h2>
        <button className="cta-button" onClick={handleRequestDemo}>Get Started</button>
      </footer>
    </div>
  );
};

export default Services;
