// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-top: auto;
  font-size: 1rem;
}

/* Медиазапросы для адаптивности */

@media (max-width: 768px) {
  .footer {
    padding: 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px;
    font-size: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA,kCAAkC;;AAElC;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":[".footer {\n  background-color: #333;\n  color: #fff;\n  padding: 10px;\n  text-align: center;\n  width: 100%;\n  margin-top: auto;\n  font-size: 1rem;\n}\n\n/* Медиазапросы для адаптивности */\n\n@media (max-width: 768px) {\n  .footer {\n    padding: 15px;\n    font-size: 0.9rem;\n  }\n}\n\n@media (max-width: 480px) {\n  .footer {\n    padding: 20px;\n    font-size: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
