// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lshaped-kitchen {
  padding: 20px;
}

.lshaped-kitchen .header {
  background-color: #f4f4f4;
  padding: 10px 0;
  text-align: center;
}

.lshaped-kitchen .content {
  margin: 20px 0;
  text-align: center;
}

.lshaped-kitchen .content img {
  width: 100%;
  height: auto;
  max-width: 600px;
  display: block;
  margin: 0 auto 20px;
}

.lshaped-kitchen .content p {
  margin: 20px auto;
  max-width: 600px;
  line-height: 1.6;
}

/* Медиазапросы для адаптивности */

@media (max-width: 768px) {
  .lshaped-kitchen {
    padding: 15px;
  }

  .lshaped-kitchen .header {
    padding: 8px 0;
  }

  .lshaped-kitchen .content img {
    max-width: 90%;
  }

  .lshaped-kitchen .content p {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .lshaped-kitchen {
    padding: 10px;
  }

  .lshaped-kitchen .header {
    padding: 5px 0;
  }

  .lshaped-kitchen .content img {
    max-width: 100%;
  }

  .lshaped-kitchen .content p {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/LShapedKitchen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,kCAAkC;;AAElC;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".lshaped-kitchen {\n  padding: 20px;\n}\n\n.lshaped-kitchen .header {\n  background-color: #f4f4f4;\n  padding: 10px 0;\n  text-align: center;\n}\n\n.lshaped-kitchen .content {\n  margin: 20px 0;\n  text-align: center;\n}\n\n.lshaped-kitchen .content img {\n  width: 100%;\n  height: auto;\n  max-width: 600px;\n  display: block;\n  margin: 0 auto 20px;\n}\n\n.lshaped-kitchen .content p {\n  margin: 20px auto;\n  max-width: 600px;\n  line-height: 1.6;\n}\n\n/* Медиазапросы для адаптивности */\n\n@media (max-width: 768px) {\n  .lshaped-kitchen {\n    padding: 15px;\n  }\n\n  .lshaped-kitchen .header {\n    padding: 8px 0;\n  }\n\n  .lshaped-kitchen .content img {\n    max-width: 90%;\n  }\n\n  .lshaped-kitchen .content p {\n    max-width: 90%;\n  }\n}\n\n@media (max-width: 480px) {\n  .lshaped-kitchen {\n    padding: 10px;\n  }\n\n  .lshaped-kitchen .header {\n    padding: 5px 0;\n  }\n\n  .lshaped-kitchen .content img {\n    max-width: 100%;\n  }\n\n  .lshaped-kitchen .content p {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
