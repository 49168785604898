// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import RequestDemo from './pages/RequestDemo';
import MultisurfaceDemo from './pages/MultisurfaceDemo'; 
import LShapedKitchen from './pages/LShapedKitchen'; 
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/request-demo" element={<RequestDemo />} />
            <Route path="/multisurface-demo" element={<MultisurfaceDemo />} /> 
            <Route path="/l-shaped-kitchen" element={<LShapedKitchen />} /> 
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
