import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import background from '../assets/static/background.png';
import main from '../assets/static/main.png';
import table from '../assets/static/table.png';
import flower from '../assets/static/flower.png';
import blackWall from '../assets/walls/black_wall.png';
import greenWall from '../assets/walls/green_wall.png';
import lightOrange from '../assets/walls/light_orange.png';
import lightOrangeWall from '../assets/walls/light_orange_wall.png';
import lightGrayWall from '../assets/walls/lightgray_wall.png';
import orangeWall from '../assets/walls/orange_wall.png';
import redWall from '../assets/walls/red_wall.png';
import whiteWall from '../assets/walls/white_wall.png';
import yellowWall from '../assets/walls/yellow_wall.png';
import floor1 from '../assets/floors/floor_1.png';
import floor2 from '../assets/floors/floor_2.png';
import floor3 from '../assets/floors/floor_3.png';
import floor4 from '../assets/floors/floor_4.png';
import floor5 from '../assets/floors/floor_5.png';
import floor6 from '../assets/floors/floor_6.png';
import './Visualizer.css';

const VisualizerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80vh; /* Adjusted height */
  background-color: #fff;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.visible {
    opacity: 1;
  }
`;

const Visualizer = () => {
  const wallImages = [blackWall, greenWall, lightOrange, lightOrangeWall, lightGrayWall, orangeWall, redWall, whiteWall, yellowWall];
  const floorImages = [floor1, floor2, floor3, floor4, floor5, floor6];

  const [currentWall, setCurrentWall] = useState(0);
  const [currentFloor, setCurrentFloor] = useState(0);

  useEffect(() => {
    const wallInterval = setInterval(() => {
      setCurrentWall((prev) => (prev + 1) % wallImages.length);
    }, 3000);

    const floorInterval = setInterval(() => {
      setCurrentFloor((prev) => (prev + 1) % floorImages.length);
    }, 4000);

    return () => {
      clearInterval(wallInterval);
      clearInterval(floorInterval);
    };
  }, [wallImages.length, floorImages.length]);

  return (
    <VisualizerContainer className="visualizer-container">
      <div className="overlay-text">
        <h1>Boost Your Sales with High-End Interior Configurators</h1>
        <p>Enhance customer experiences and drive sales with our cutting-edge interior configurators. Transform the way your customers interact with your products, both online and in-store.</p>
        <Link to="/request-demo">
          <button className="call-to-action">Request a demo</button>
        </Link>
      </div>
      <Image src={background} style={{ zIndex: 1 }} className="visible" />
      <Image src={main} style={{ zIndex: 2 }} className="visible" />
      {wallImages.map((img, index) => (
        <Image
          key={index}
          src={img}
          style={{ zIndex: 3 }}
          className={index === currentWall ? 'visible' : ''}
        />
      ))}
      {floorImages.map((img, index) => (
        <Image
          key={index}
          src={img}
          style={{ zIndex: 4 }}
          className={index === currentFloor ? 'visible' : ''}
        />
      ))}
      <Image src={table} style={{ zIndex: 5 }} className="visible" />
      <Image src={flower} style={{ zIndex: 6 }} className="visible" />
    </VisualizerContainer>
  );
};

export default Visualizer;
